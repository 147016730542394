import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ButtonTextOnly from '../../../src/images/accessibility/color-considerations/button-text-only.png';
import ButtonFunctionalIcon from '../../../src/images/accessibility/color-considerations/button-functional-icon.png';
import ButtonDecorativeIcon from '../../../src/images/accessibility/color-considerations/button-decorative-icon.png';
import InputBorderContrast from '../../../src/images/accessibility/color-considerations/input-border-contrast.png';
import InputSelectionState from '../../../src/images/accessibility/color-considerations/input-selection-state.png';
import InputText from '../../../src/images/accessibility/color-considerations/input-text.png';
import InputValidationState from '../../../src/images/accessibility/color-considerations/input-validation-state.png';
import SearchCollapsed from '../../../src/images/accessibility/color-considerations/search-collapsed.png';
import SearchExpanded from '../../../src/images/accessibility/color-considerations/search-expanded.png';
import ThemePicker from '../../../src/images/accessibility/color-considerations/theme-picker.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Overview`}</h2>
    <p>{`This guidance covers considerations that can't automatically be integrated into Primer. It supplements `}<a parentName="p" {...{
        "href": "https://primer.style/foundations/color/accessibility"
      }}>{`accessibility guidance given in the Color portion of our Foundations documentation`}</a>{`.`}</p>
    <h3>{`Design tokens`}</h3>
    <p>{`The majority of color contrast considerations should be handled by `}<a parentName="p" {...{
        "href": "https://primer.style/foundations/color/overview"
      }}>{`Primer color design tokens`}</a>{`. Ensure that solutions for the following considerations integrate design tokens as a method of creating accessible experiences.`}</p>
    <h3>{`Determining contrast`}</h3>
    <p><a parentName="p" {...{
        "href": "https://webaim.org/resources/contrastchecker/"
      }}>{`WebAIM's Contrast Checker tool`}</a>{` is the recommended way to calculate color contrast ratios.`}</p>
    <h3>{`Level AA support`}</h3>
    <p>{`GitHub is `}<strong parentName="p">{`required`}</strong>{` to honor WCAG AA-level compliance. This means that WCAG Success Criterion levels A and AA are `}<strong parentName="p">{`both`}</strong>{` observed.`}</p>
    <p>{`An example level A Success Criterion is `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/use-of-color.html"
      }}>{`SC 1.4.1 Use of Color`}</a>{`. An example level AA Success Criterion is `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html"
      }}>{`SC 1.4.3 Contrast (Minimum)`}</a>{`.`}</p>
    <h2>{`Compliance`}</h2>
    <p>{`The following guidance is based on one or more `}<strong parentName="p">{`normative`}</strong>{` Web Content Accessibility Guidelines (`}<abbr>{`WCAG`}</abbr>{`) success criterion. This means that the guidance is based on objective requirements with implications that affect `}<a parentName="p" {...{
        "href": "https://github.com/github/accessibility/blob/main/docs/measuring-ourselves/microsoft-accessibility-standards.md#microsoft-accessibility-standards-mas"
      }}>{`MAS-C compliance efforts`}</a>{`, as well as international laws regarding accessibility.`}</p>
    <p>{`An example of `}<strong parentName="p">{`the difference between non-normative and normative considerations`}</strong>{` would be the difference between the overall choice of color used, and then the actual value of the color.`}</p>
    <h3>{`Non-normative choices`}</h3>
    <p>{`A design could use a dark shade of purple as the background color as a `}<strong parentName="p">{`non-normative aesthetic choice`}</strong>{`. Reasons for going with dark purple could be:`}</p>
    <ul>
      <li parentName="ul">{`The team prefers how it looks over dark blue, dark green, dark gray, etc.,`}</li>
      <li parentName="ul">{`The dark purple is a subtle reference to a brighter purple that is a core part of main palette,`}</li>
      <li parentName="ul">{`The dark purple is part of a new branding update,`}</li>
      <li parentName="ul">{`Etc.`}</li>
    </ul>
    <h3>{`Normative requirements`}</h3>
    <p>{`The `}<strong parentName="p">{`normative requirement`}</strong>{` for going with dark purple would be `}<a parentName="p" {...{
        "href": "https://webaim.org/resources/contrastchecker/?fcolor=FFFFFF&bcolor=510094"
      }}>{`comparing the final computed color value used for the dark purple background color against the color value used for text`}</a>{`.`}</p>
    <h3>{`The APCA`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.myndex.com/APCA/"
      }}>{`Advanced Accessible Perceptual Contrast Algorithm`}</a>{` (`}<abbr>{`APCA`}</abbr>{`) is a proposal and is `}<strong parentName="p">{`not normative`}</strong>{`. In terms of WCAG, and therefore, MAS-C compliance, this means that color contrast is valid `}<strong parentName="p">{`only`}</strong>{` if it meets WCAG 2.1 criteria.`}</p>
    <p>{`A color value that is APCA-compliant `}<strong parentName="p">{`might not`}</strong>{` be WCAG-compliant until APCA is integrated into WCAG 3.0. WCAG 3.0 is still a work in progress and currently does not have an anticipated release date.`}</p>
    <h2>{`Visual themes`}</h2>
    <p>{`GitHub offers two parent themes:`}</p>
    <ol>
      <li parentName="ol">{`Day (light), and`}</li>
      <li parentName="ol">{`Night (dark).`}</li>
    </ol>
    <p>{`Each parent theme has a default appearance, and then a suite of sub-themes. Sub-themes include considerations for high and low contrast experiences, as well as certain vision conditions.`}</p>
    <img src={ThemePicker} alt="GitHub's theme picker showing Day and Night parent themes, with swatches for each of the nine sub-themes. The Day theme is active. Cropped screenshot'" />
    <p>{`It is possible to set GitHub to use a dark sub-theme when a parent Day theme has been set, and vice-versa. This allows a user to have a fine-grained control over their experience.`}</p>
    <h3>{`Default theme`}</h3>
    <p>{`GitHub uses:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`The default Day (light) theme`}</strong>{` when a user visits the site in a logged out state and their operating system is set to light mode.`}</li>
      <li parentName="ul"><strong parentName="li">{`The default Night (dark) theme`}</strong>{` when a user visits the site in a logged out state and their operating system is set to dark mode.`}</li>
      <li parentName="ul">{`Whatever theme the user has set in their account settings.`}</li>
    </ul>
    <p>{`When not logged in, users are unable to adjust the theme in the `}<a parentName="p" {...{
        "href": "https://github.com/settings/appearance"
      }}>{`Appearance settings`}</a>{`.`}</p>
    <p>{`Because of this, `}<a parentName="p" {...{
        "href": "https://github.com/github/accessibility/blob/main/docs/coaching-recommendations/primer/color-modes.md#guidance"
      }}>{`the default Day `}<strong parentName="a">{`and`}</strong>{` Night theme `}<strong parentName="a">{`must both`}</strong>{` satisfy WCAG criteria for color-related concerns`}</a>{`. From a high-level, this means that `}<strong parentName="p">{`all`}</strong>{` GitHub content displayed using the default Day and Night theme `}<strong parentName="p">{`must`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`Pass color contrast requirements, and`}</li>
      <li parentName="ul">{`Have the mechanism for accessing and changing the theme be fully WCAG compliant.`}</li>
    </ul>
    <p>{`This is the strongest guarantee we have that:`}</p>
    <ol>
      <li parentName="ol">{`Content can be perceived—and therefore understood and used—across a wide range of vision conditions, and that`}</li>
      <li parentName="ol">{`The mechanism to adjust GitHub's theme can be operated by the widest range of input modes.`}</li>
    </ol>
    <p>{`This allows users to use GitHub in a logged-out state. If a user is logged in, it also enables them to address their own specific usability and access needs, as well as aesthetic desires.`}</p>
    <h3>{`Non-default themes`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/github/accessibility/blob/main/docs/coaching-recommendations/primer/color-modes.md#guidance"
      }}><strong parentName="a">{`All`}</strong>{` Day and Night sub-themes `}<strong parentName="a">{`do not`}</strong>{` need to meet WCAG color contrast requirements`}</a>{`, provided all default Day and Night theme content is accessible. This guidance is derived from `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Techniques/general/G174"
      }}>{`WCAG 2.1 Technique G174`}</a>{`.`}</p>
    <p>{`Certain themes are specifically designed to create a low contrast effect, notably the Dark Dimmed sub-theme. In this use case, a user selecting this theme is likely `}<strong parentName="p">{`intentionally`}</strong>{` expressing a desire for an experience with less visual contrast.`}</p>
    <p>{`Like other theme selections, this may be to satisfy:`}</p>
    <ol>
      <li parentName="ol">{`Aesthetic sensibilities,`}</li>
      <li parentName="ol">{`Access needs that are derived from visual conditions that make a higher contrast experience unusable, or`}</li>
      <li parentName="ol">{`A combination of both.`}</li>
    </ol>
    <p>{`In the case of access needs, a higher contrast experience may actually be less accessible for the user.`}</p>
    <p>{`The availability of the Dark Dimmed theme treatment allows users to customize their GitHub experience without having to resort to browser extensions or other user-hosted modifications. Of note is the portability of this theme, in that it will automatically be available across multiple sessions, browsers, and devices.`}</p>
    <p>{`GitHub should strive to ensure all themes that don't specifically deal with an intentionally-lowered contrast experience are color contrast compliant. While this is not a normative requirement, it proactively helps an unknown number of users with unknown access needs.`}</p>
    <h3>{`High contrast themes, high contrast mode, and forced colors mode`}</h3>
    <p>{`GitHub offers two high contrast sub-themes:`}</p>
    <ol>
      <li parentName="ol">{`Light High Contrast, and`}</li>
      <li parentName="ol">{`Dark High Contrast.`}</li>
    </ol>
    <p>{`In addition some, but `}<strong parentName="p">{`not all`}</strong>{` operating systems offer forced color and high contrast mode experiences.`}</p>
    <h4>{`High contrast themes`}</h4>
    <p>{`These `}<strong parentName="p">{`are`}</strong>{` provided by GitHub.`}</p>
    <p>{`High contrast themes can be enabled with or without forced color and high contrast modes enabled. Some users may be unaware of, or unable to access these modes. Other users may not need them.`}</p>
    <h4>{`High contrast mode`}</h4>
    <p>{`This `}<strong parentName="p">{`is not`}</strong>{` provided by GitHub.`}</p>
    <p>{`A high contrast mode is a signal to the operating system to increase the visual distinction between different UI elements. On the web, it is a flag that can be targeted with a media query.`}</p>
    <p>{`The presence of an enabled high contrast mode experience does not guarantee a high contrast web experience is generated—that is an experience the website has to intentionally provide.`}</p>
    <p>{`GitHub currently `}<strong parentName="p">{`does not`}</strong>{` auto-opt a user into its high contrast themes if a user has enabled high contrast mode on their device.`}</p>
    <h4>{`Forced colors mode`}</h4>
    <p>{`This `}<strong parentName="p">{`is not`}</strong>{` provided by GitHub.`}</p>
    <p>{`Forced color mode is another media query. Unlike high contrast mode, forced colors mode automatically adapts `}<strong parentName="p">{`all`}</strong>{` operating system content. This includes `}<strong parentName="p">{`all`}</strong>{` web content.`}</p>
    <p>{`A forced color mode will update all GitHub content's visual presentation regardless of what theme a user has selected. It uses a suite of heuristics to determine content meaning and then assign a dynamic color keyword value to it.`}</p>
    <p>{`Forced color mode content presentation `}<strong parentName="p">{`should not`}</strong>{` be overridden to satisfy aesthetic desires. Instead, it should only be lightly adjusted to ensure maximum legibility and accuracy of applied color keywords.`}</p>
    <h2>{`Inputs and buttons`}</h2>
    <p><strong parentName="p">{`Different kinds of UI have their contrast compliance determined in different ways`}</strong>{`. This is because different parts of them are visually significant for understanding purpose.`}</p>
    <p>{`The difference between `}<strong parentName="p">{`inputs`}</strong>{` and `}<strong parentName="p">{`buttons`}</strong>{` may be confusing. Understanding the difference between the two types of UI helps to understand why they are evaluated differently for color contrast purposes.`}</p>
    <p>{`At a high-level:`}</p>
    <ul>
      <li parentName="ul">{`Buttons `}<strong parentName="li">{`perform a predetermined action`}</strong>{` when pressed. Examples of this are submitting form input, triggering a dialog, or saving user-entered content.`}</li>
      <li parentName="ul">{`Inputs `}<strong parentName="li">{`collect information for eventual submission`}</strong>{`. Examples of this are single and multiline text-entry areas, checkbox selections/radio choices, and comboboxes.`}</li>
    </ul>
    <p>{`Inputs and buttons are combined to form data collection and transmission experiences such as a contact form or a Pull Request submission.`}</p>
    <h3>{`Inputs`}</h3>
    <p>{`Being able to perceive the boundaries of an input is vital. Meeting WCAG color contrast requirements helps to ensure the widest possible range of users can perform the data entry required to operate GitHub successfully.`}</p>
    <h4>{`Input considerations`}</h4>
    <p>{`Broadly-speaking, there are four overall considerations for input contrast:`}</p>
    <ol>
      <li parentName="ol">{`Text,`}</li>
      <li parentName="ol">{`Borders,`}</li>
      <li parentName="ol">{`Selected state, and`}</li>
      <li parentName="ol">{`Validation state.`}</li>
    </ol>
    <p>{`Combined, meeting contrast requirements allow a user to understand:`}</p>
    <ol>
      <li parentName="ol">{`What the input is for,`}</li>
      <li parentName="ol">{`What area of the input a user can interact with,`}</li>
      <li parentName="ol">{`What information a user has entered into the input, and`}</li>
      <li parentName="ol">{`What state the input is in.`}</li>
    </ol>
    <p>{`If a computed color value in any of the four considerations is not compliant, it may endanger one or more portions of user understanding and operation of the input.`}</p>
    <img src={InputText} alt="A wireframe illustration of a text input. Its label reads, 'Name.' It's user-provided text content reads, 'Mona Lisa.' A label pointing to the label reads, 'Communicates purpose.' A label pointing to the user provided content reads, 'Communicates user choice.'" />
    <h4>{`Input text contrast`}</h4>
    <p>{`Text portions of an input `}<strong parentName="p">{`must`}</strong>{` meet a contrast ratio of `}<inlineCode parentName="p">{`4.5:1`}</inlineCode>{` for "normal text", or `}<inlineCode parentName="p">{`3:1`}</inlineCode>{` for "large text". This applies to both an input's visible label and its user-provided text content.`}</p>
    <p>{`The majority of GitHub input text size is "normal text". Both "normal text" and "large text" are WCAG-specific terms. More information about this can be found on `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html#dfn-large-scale"
      }}>{`the Key Terms subsection of SC 1.4.3: Contrast (Minimum)`}</a>{`.`}</p>
    <h4>{`Input border contrast`}</h4>
    <p>{`Borders communicate `}<strong parentName="p">{`the boundaries of an input`}</strong>{`, and are considered non-text contrast. This includes inputs that allow for freeform user input such as text, and also pre-specified inputs such as checkboxes.`}</p>
    <p>{`Borders of an input `}<strong parentName="p">{`must`}</strong>{` meet a contrast ratio of `}<inlineCode parentName="p">{`3:1`}</inlineCode>{`. This is calculated by the border's computed color value compared to the computed background color the input is placed on.`}</p>
    <img src={InputBorderContrast} alt="A wireframe illustration of a text input with a border. Its label reads, 'Email address.' A label pointing to the input's border reads, 'Communicates interactive area boundary.'" />
    <p>{`The contrast of the input's border color compared to the input's background color `}<strong parentName="p">{`does not`}</strong>{` need to meet a contrast ratio. The color of `}<a parentName="p" {...{
        "href": "#input-text-contrast"
      }}>{`the input's text against its background color`}</a>{` still needs to be compliant, however.`}</p>
    <p>{`Inputs without visible borders must use their background color instead. In this case, the required contrast ratio is `}<inlineCode parentName="p">{`3:1`}</inlineCode>{`, with the computed input background color and the color of the background of what the input is placed on.`}</p>
    <img src={InputBorderContrast} alt="A wireframe illustration of a text input without a border. Its label reads, 'Phone number.' It's user-provided text content reads, '123-456-7890.' A label pointing to the input's background reads, 'Communicates interactive area boundary.'" />
    <h4>{`Input selected state contrast`}</h4>
    <p>{`Selection state communicates `}<strong parentName="p">{`if a user has chosen an input option`}</strong>{`, and is considered non-text contrast. Examples of this are a checkbox's check or a radio's dot.`}</p>
    <img src={InputSelectionState} alt="A wireframe illustration of a checkbox input with a checkmark indicating it has been placed in a selected state. A label pointing to the checkmark reads, 'Communicates user choice.' A label pointing to the checkbox's border reads, 'Communicates interactive area boundary.'" />
    <p>{`Selected state of an input such as a checkbox `}<strong parentName="p">{`must`}</strong>{` meet a contrast ratio of `}<inlineCode parentName="p">{`3:1`}</inlineCode>{`. This is calculated by the border's computed color value compared to the computed background color the input is placed on.`}</p>
    <h4>{`Input validation state contrast`}</h4>
    <p>{`Validation state communicates if the content a user has submitted meets the intake requirements or not. Examples of this are an inline error message with a text input, or a checkmark icon to indicate password criteria have been met.`}</p>
    <p>{`Validation state `}<strong parentName="p">{`must not`}</strong>{` rely on color as the sole way of determining if an input is valid. Accompanying text or icons can help accomplish this.`}</p>
    <img src={InputValidationState} alt="A wireframe illustration of a text input with an error message and icon. Its label reads, 'Email address.' It's user-provided text content reads, 'asdf.' The error message reads, 'Error message', and the the error icon is a warning symbol. A label pointing to the error message reads, 'Communicates validation status.' A label pointing to the text input's border reads, 'Reinforces validation status.'" />
    <ul>
      <li parentName="ul">{`If the validation message is `}<strong parentName="li">{`only text`}</strong>{`, it `}<strong parentName="li">{`must`}</strong>{` meet a contrast ratio of `}<inlineCode parentName="li">{`4.5:1`}</inlineCode>{` for "normal text", or `}<inlineCode parentName="li">{`3:1`}</inlineCode>{` for "large text".`}</li>
      <li parentName="ul">{`If the validation message is `}<strong parentName="li">{`only`}</strong>{` an icon, it `}<strong parentName="li">{`must`}</strong>{` meet a contrast ratio of `}<inlineCode parentName="li">{`3:1`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`If the validation message is both `}<strong parentName="li">{`text and an icon`}</strong>{`, it `}<strong parentName="li">{`must`}</strong>{` meet a contrast ratio of `}<inlineCode parentName="li">{`4.5:1`}</inlineCode>{` for "normal text", or `}<inlineCode parentName="li">{`3:1`}</inlineCode>{` for "large text". In this context the icon is considered decorative. It is still encouraged to make the icon meet a `}<inlineCode parentName="li">{`3:1`}</inlineCode>{` contrast ratio, however.`}</li>
    </ul>
    <p>{`Contrast for validation state is calculated by the text or icon's computed color value compared to the computed background color the input is placed on.`}</p>
    <h3>{`Buttons`}</h3>
    <p>{`Being able to perceive the name or icon of a button is vital. Meeting WCAG color contrast requirements helps to ensure the widest possible range of users can perform the actions required to operate GitHub successfully.`}</p>
    <h4>{`Button considerations`}</h4>
    <p>{`Broadly-speaking, there are four overall considerations for button contrast:`}</p>
    <ol>
      <li parentName="ol">{`Text,`}</li>
      <li parentName="ol">{`Icons,`}</li>
      <li parentName="ol">{`Borders, and`}</li>
      <li parentName="ol">{`Disabled state.`}</li>
    </ol>
    <p>{`Combined, meeting contrast requirements allow a user to understand:`}</p>
    <ol>
      <li parentName="ol">{`What the button is for, and`}</li>
      <li parentName="ol">{`What area of the button a user can interact with.`}</li>
    </ol>
    <h4>{`Button text contrast`}</h4>
    <p>{`The visible text portion that communicates the button's purpose `}<strong parentName="p">{`must`}</strong>{` meet a contrast ratio of `}<inlineCode parentName="p">{`4.5:1`}</inlineCode>{` for "normal text", or `}<inlineCode parentName="p">{`3:1`}</inlineCode>{` for "large text".`}</p>
    <img src={ButtonTextOnly} alt="A wireframe illustration of a button. Its text action reads, 'Cancel.' A label pointing to the text action reads, 'Communicates purpose.' A label pointing to the button's background and border reads, 'Reinforces interactive area.'" />
    <p>{`The majority of GitHub input text size is "normal text". Both "normal text" and "large text" are WCAG-specific terms. More information about this can be found on `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html#dfn-large-scale"
      }}>{`the Key Terms subsection of SC 1.4.3: Contrast (Minimum)`}</a>{`.`}</p>
    <h4>{`Button icon contrast`}</h4>
    <p>{`Icons can either be:`}</p>
    <ol>
      <li parentName="ol">{`Functional, or`}</li>
      <li parentName="ol">{`Decorative.`}</li>
    </ol>
    <h5>{`Functional icons`}</h5>
    <p>{`If the icon is the sole means of determining a button's purpose, it is considered functional. An example of this is an `}<a parentName="p" {...{
        "href": "https://primer.style/components/icon-button"
      }}>{`IconButton`}</a>{`.`}</p>
    <img src={ButtonFunctionalIcon} alt="A wireframe illustration of a button. It only has the GitHub Octocat logo in its center, and no text. A label pointing to the icon reads, 'Communicates purpose.' A label pointing to the button's background and border reads, 'Reinforces interactive area.'" />
    <p>{`Functional icons `}<strong parentName="p">{`must`}</strong>{` meet a contrast ratio of `}<inlineCode parentName="p">{`3:1`}</inlineCode>{`. This is calculated by the icon's computed color value compared to the computed background color the input is placed on.`}</p>
    <p>{`Beyond the scope of normative WCAG guidance, a further consideration is if the icon's meaning has cultural considerations or represents abstract functionality a user may not be familiar with.`}</p>
    <h5>{`Decorative icons`}</h5>
    <p>{`Buttons with a text label that `}<strong parentName="p">{`also`}</strong>{` have an accompanying icons can consider their icons as decorative. Decorative icons `}<strong parentName="p">{`do not`}</strong>{` need to meet WCAG-compliant contrast ratios.`}</p>
    <p>{`The reason for this is that the text label communicates the input's purpose, provided its computed color value is WCAG compliant. The icon in this circumstance reinforces the text label.`}</p>
    <p>{`An example of this is a button with both a `}<a parentName="p" {...{
        "href": "https://primer.style/foundations/icons/trash-16"
      }}>{`trash Octicon`}</a>{` `}<strong parentName="p">{`and`}</strong>{` a text label of "Delete".`}</p>
    <img src={ButtonDecorativeIcon} alt="A wireframe illustration of a button. It only has the GitHub Octocat logo in its center, and no text. A label pointing to the icon reads, 'Communicates purpose.' A label pointing to the button's background and border reads, 'Reinforces interactive area.'" />
    <p>{`Beyond the scope of normative WCAG guidance, a further consideration is if making a decorative icon color contrast compliant is useful for aiding in facilitating understanding, even if it is not required to be.`}</p>
    <h4>{`Button border contrast`}</h4>
    <p>{`The contrast of a button's border color compared to the input's background color `}<strong parentName="p">{`does not`}</strong>{` need to meet a contrast ratio. Only the `}<a parentName="p" {...{
        "href": "#button-text-contrast"
      }}>{`button's text`}</a>{` or `}<a parentName="p" {...{
        "href": "#functional-icons"
      }}>{`functional icon`}</a>{` does.`}</p>
    <h4>{`Button disabled state contrast`}</h4>
    <p>{`Broadly-speaking, buttons `}<strong parentName="p">{`should not`}</strong>{` be disabled until form validation criteria has been met. An example of behavior to avoid is disabling a submit button until a user has filled out all required fields.`}</p>
    <p>{`If a button needs to be placed in a disabled state, `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/non-text-contrast.html#inactive-controls"
      }}>{`it `}<strong parentName="a">{`does not`}</strong>{` need to be color contrast compliant`}</a>{`. Other WCAG criteria may affect this treatment, however.`}</p>
    <p>{`Beyond the scope of normative WCAG guidance, a further consideration is there are other ways to visually communicate a disabled button, or to design the system in such a way that a button cannot circumstantially be placed in a disabled state.`}</p>
    <p>{`While a button's disabled state is exempt from normative compliance, consider that the control may be invisible or unpredictably disappear from the perspective of a user experiencing low vision conditions.`}</p>
    <h2>{`Patterns that might be confusing`}</h2>
    <p>{`Following are examples of unique GitHub UI that are worth specifically documenting:`}</p>
    <h3>{`GitHub's search`}</h3>
    <p>{`GitHub's search `}<strong parentName="p">{`visually`}</strong>{` looks like an input, but is coded as a button. Use of this approach is discouraged. The following documentation communicates how normative WCAG color contrast rules apply.`}</p>
    <img src={SearchCollapsed} alt="GitHub's search in its collapsed state. It visually looks like a text input with a magnifying glass icon and placeholder text that reads, 'Type forward slash to search.' An accompanying faux submit button is also present, that looks like a command line prompt awaiting input. A label is pointing to the entire width of the search input and indicates that it is a HTML button element. Cropped screenshot." />
    <p>{`This is because it triggers the dialog that displays the full search interface—a user `}<strong parentName="p">{`cannot`}</strong>{` directly enter text into a faux-input field. Because of this, it is `}<strong parentName="p">{`treated like a button`}</strong>{` in terms of normative color contrast evaluation.`}</p>
    <p>{`When the search dialog is displayed, the input that a user types in is treated as a text input for color contrast purposes.`}</p>
    <img src={SearchExpanded} alt="GitHub's search in its expanded state. It visually looks like a text input with a magnifying glass icon. Below it are lists of popular owners and repositories, with the prompt to navigate to each entry. A label is pointing to the text input portion of the search input and indicates that it is a HTML input element with an attribute of 'text.' Cropped screenshot." />
    <h2>{`Further reading`}</h2>
    <p>{`Resources, articles, and guidelines that informed this documentation:`}</p>
    <h3>{`Resources`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.a11yproject.com/posts/operating-system-and-browser-accessibility-display-modes/"
        }}>{`Assistive technology: Operating System and Browser Accessibility Display Modes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://webaim.org/resources/contrastchecker/"
        }}>{`WebAIM Contrast Checker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Techniques/general/G174"
        }}>{`Technique G174: Providing a control with a sufficient contrast ratio that allows users to switch to a presentation that uses sufficient contrast`}</a></li>
    </ul>
    <h3>{`Primer`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://primer.style/foundations/color/overview"
        }}>{`Foundations/Color`}</a></li>
    </ul>
    <h3>{`WCAG`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/use-of-color.html"
        }}>{`SC 1.4.1 Use of Color`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html"
        }}>{`SC 1.4.3 Contrast (Minimum)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/non-text-contrast.html"
        }}>{`SC 1.4.11 Non-text Contrast`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/focus-visible.html"
        }}>{`SC 2.4.7 Focus Visible`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Techniques/general/G174"
        }}>{`WCAG 2.1 Technique G174: Providing a control with a sufficient contrast ratio that allows users to switch to a presentation that uses sufficient contrast`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      